import React from 'react';
import styled, { css, keyframes } from 'styled-components';

import { DSTokenMap } from '@rover/kibble/styles';

const LEFT_MARGIN_PX = 2;
const ballKeyFrame = keyframes`
  0% {
    transform: translateX(0);
    opacity: 1;
  }

  25% {
    transform: translateX(calc(100% + ${LEFT_MARGIN_PX}px));
    opacity: 0.7;
  }

  50% {
    transform: translateX(calc(200% + ${2 * LEFT_MARGIN_PX}px));
    opacity: 0.4;
  }

  75% {
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;
const ballKeyFrame2 = keyframes`
  0% {
    transform: translateX(0);
    opacity: 0.7;
  }

  25% {
    transform: translateX(calc(-100% - ${LEFT_MARGIN_PX}px));
    opacity: 1;
  }

  50% {
    transform: translateX(calc(-100% - ${LEFT_MARGIN_PX}px));
    opacity: 1;
  }

  75% {
    transform: translateX(calc(100% + ${LEFT_MARGIN_PX}px));
    opacity: 0.4;
  }

  100% {
    transform: translateX(0);
    opacity: 0.7;
  }
`;
const ballKeyFrame3 = keyframes`
  0% {
    transform: translateX(0);
    opacity: 0.4;
  }

  25% {
    transform: translateX(0);
    opacity: 0.4;
  }

  50% {
    transform: translateX(calc(-100% - ${LEFT_MARGIN_PX}px));
    opacity: 0.7;
  }

  75% {
    transform: translateX(calc(-100% - ${LEFT_MARGIN_PX}px));
    opacity: 0.7;
  }

  100% {
    transform: translateX(0);
    opacity: 0.4;
  }
`;
const LoadingWrapper = styled.span`
  display: flex;
  margin-left: -${LEFT_MARGIN_PX}px;
`;
const LoadingBall = styled.div<{ offset: number }>`
  height: 6px;
  width: 6px;
  background-color: ${DSTokenMap.BACKGROUND_COLOR_CONTRAST.toString()};
  border-radius: 50%;

  margin-left: ${LEFT_MARGIN_PX}px;

  animation: ${({ offset }) => {
    const keyframeAnimation = (() => {
      switch (offset) {
        case 0:
          return ballKeyFrame;

        case 1:
          return ballKeyFrame2;

        case 2:
          return ballKeyFrame3;

        default:
          return ballKeyFrame;
      }
    })();

    return css`1.5s ${keyframeAnimation} ease-in-out infinite forwards`;
  }};
`;
type Props = {
  className?: string;
  dataTestId?: string;
};

const LoadingIndicator = ({ className, dataTestId }: Props) => (
  <LoadingWrapper data-testid={dataTestId} className={className}>
    <LoadingBall offset={0} />
    <LoadingBall offset={1} />
    <LoadingBall offset={2} />
  </LoadingWrapper>
);

export default LoadingIndicator;
