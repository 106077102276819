import React, { FC } from 'react';

import { Box } from '@rover/kibble/core';
import { ZIndex } from '@rover/kibble/styles';

const HideBoxShadow: FC = () => {
  return (
    <Box
      height="6px"
      width="calc(100% - 2px)"
      position="absolute"
      left="1px"
      bottom="0"
      sx={{ backgroundColor: 'white', zIndex: `calc(${ZIndex.DROPDOWN.toNumber()} + 1)` }}
    />
  );
};

export default HideBoxShadow;
