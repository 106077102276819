import React, { FC } from 'react';

import { Box } from '@rover/kibble/core';

type Props = {
  title: string;
  children: React.ReactNode;
};

const OptionLabel: FC<Props> = ({ title, children }) => {
  return (
    <Box title={title} sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
      {children}
    </Box>
  );
};

export default OptionLabel;
