import React from 'react';
import { MessageDescriptor } from '@lingui/core';
import { t } from '@lingui/macro';
import styled from 'styled-components';

import { Paragraph } from '@rover/kibble/core';
import { DSTokenMap } from '@rover/kibble/styles';
import { Alignment } from '@rover/react-lib/src/components/layouts/layout.constants';
import VerticalLayout from '@rover/react-lib/src/components/layouts/VerticalLayout';
import DialogModal from '@rover/react-lib/src/components/modals/DialogModal';
import { useI18n } from '@rover/rsdk/src/modules/I18n';

export type Props = {
  message: MessageDescriptor | string;
  onRequestClose: () => void;
};
const StyledModalContent = styled.div`
  padding: ${DSTokenMap.SPACE_6X};
`;

const LocationErrorModal = ({ message, onRequestClose }: Props): JSX.Element => {
  const { i18n } = useI18n();
  return (
    <DialogModal
      cancelButtonText={null}
      headerText={null}
      isOpen={!!message}
      onClickCancel={null}
      onClickSubmit={onRequestClose}
      onRequestClose={onRequestClose}
      submitButtonText={i18n._(t`Close`)}
    >
      <StyledModalContent>
        <VerticalLayout hAlign={Alignment.CENTER}>
          <Paragraph size="300">{i18n._(message as string)}</Paragraph>
        </VerticalLayout>
      </StyledModalContent>
    </DialogModal>
  );
};

export default LocationErrorModal;
