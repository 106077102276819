import React, { FC, useId } from 'react';

import LocationInput from '@rover/react-lib/src/components/formFields/LocationInput';
import {
  actions as locationInputActions,
  effects as locationInputEffects,
  selectors as locationInputSelectors,
} from '@rover/react-lib/src/components/formFields/LocationInput/LocationInput.duck';
import Connect from '@rover/react-lib/src/components/utils/Redux/Connect';
import { selectors as frontendConfigurationSelectors } from '@rover/react-lib/src/redux/ducks/frontendConfiguration.duck';

import type { ContainerProps } from './container.constants';

const effects = {
  fetchGeocodedLatLng: locationInputEffects.fetchLocationDeviceOrGeocode,
  loadOptions: locationInputEffects.fetchLocationAutocomplete,
};

const actions = {
  dismissErrorMessage: locationInputActions.dismissErrorMessage,
};

const selector = (state, id, includeCurrentLocation) => ({
  // @ts-expect-error
  geocodedLatLng: locationInputSelectors.getLocationGeocodeResponse(state, id),
  // @ts-expect-error
  geocodeResultsAreLoading: locationInputSelectors.getLocationGeocodeResultsAreLoading(state, id),
  // @ts-expect-error
  loading: locationInputSelectors.getIsLoading(state, id),
  // @ts-expect-error
  options: locationInputSelectors.getLocationAutocompleteOptions(state, id, includeCurrentLocation),
  region: frontendConfigurationSelectors.getCcTld(state),
  // @ts-expect-error
  selectedOption: locationInputSelectors.getSelectedOption(state, id),
  // @ts-expect-error
  errorMessage: locationInputSelectors.getErrorMessage(state, id),
});

type Props = ContainerProps<
  typeof LocationInput,
  // Note: Specifying the Object type for onChange cascades into several complex typescript errors
  { onChange: (Object) => void; onClear?: () => void }
> & {
  inputId?: string;
  validationType: 'popover' | 'inline';
  includeCurrentLocation?: boolean;
};

const LocationInputContainer: FC<Props> = (props) => {
  const autoGeneratedId = useId();
  const { inputId = autoGeneratedId, includeCurrentLocation = true } = props;

  return (
    <Connect
      effects={effects}
      actions={actions}
      selector={(state) => selector(state, inputId, includeCurrentLocation)}
    >
      {/* removing dispatch from connectedProps is a fine thing to do here */}
      {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
      {({ dispatch, ...connectedProps }) => (
        <LocationInput {...connectedProps} {...props} inputId={inputId} />
      )}
    </Connect>
  );
};

export default LocationInputContainer;
