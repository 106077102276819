import { useEffect, useRef } from 'react';

const usePrevious = <T>(value: T, setInitial = false): T | null => {
  // setInitial can be used in situations where you are comparing previous and
  // current if you don't want it to look "changed" on the initial render.
  const ref = useRef<T | null>(setInitial ? value : null);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

export default usePrevious;
