import { useEffect, useState } from 'react';
import { MessageDescriptor } from '@lingui/core';

import usePrevious from '@rover/react-lib/src/hooks/usePrevious';

type UseInputValue = {
  inputValue: string;
  setInputValue: (value: string) => void;
};

const useInputValue = (
  propInputValue: string = '',
  errorMessage: MessageDescriptor | string | null = null,
  clearOnFocus: boolean = false,
  inputIsFocused: boolean = false
): UseInputValue => {
  const [inputValue, setInputValue] = useState<string>(propInputValue);
  const [inputValueWhenFocused, setInputValueWhenFocused] = useState<string | null>(null);
  const prevErrorMessage = usePrevious(errorMessage);

  useEffect(() => {
    if (errorMessage && errorMessage !== prevErrorMessage && errorMessage !== '') {
      setInputValue('');
    }
  }, [errorMessage, prevErrorMessage]);

  useEffect(() => {
    if (!clearOnFocus) {
      return;
    }

    if (inputIsFocused) {
      setInputValueWhenFocused(inputValue);
      setInputValue('');
    } else if (inputValue === '' && inputValueWhenFocused) {
      setInputValue(inputValueWhenFocused);
    }
  }, [clearOnFocus, inputIsFocused]);

  useEffect(() => {
    if (propInputValue !== inputValue) {
      setInputValue(propInputValue);
    }
  }, [propInputValue]);

  return { inputValue, setInputValue };
};

export default useInputValue;
