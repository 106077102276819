import { FocusEvent, KeyboardEvent, useCallback, useLayoutEffect } from 'react';

import KEYS from '@rover/react-lib/src/constants/keys.constants';
import useDebounce from '@rover/react-lib/src/hooks/useDebounce';

import { LOAD_OPTIONS_DEBOUNCE_TIME } from '../LocationInput.constants';
import type { SelectOption } from '../LocationInput.types';
import { getSuggestionId } from '../utils';

type UseInputBodyActions = {
  handleInputChange: (newInputValue: string) => void;
  handleInputFocus: () => void;
  handleInputBlur: (e: FocusEvent<HTMLInputElement>) => void;
  handleKeydown: (e: KeyboardEvent<Element>) => void;
};

const useInputBodyActions = (
  inputId: string,
  options: SelectOption[],
  inputValue: string,
  setActiveIndex: (index: number) => void,
  handleSelectChange: (option: SelectOption) => void,
  setInputValue: (value: string) => void,
  setInputFocused: (focused: boolean) => void,
  inputRef?: React.MutableRefObject<HTMLInputElement | null>,
  activeIndex?: number | null,
  onBlur?: () => void,
  onInputChange?: (value: string) => void,
  loadOptions?: (params: { query: string; inputId: string }) => void
): UseInputBodyActions => {
  const fetchOptions = useCallback(
    (newInputValue: string): void => {
      if (newInputValue && loadOptions) {
        loadOptions({
          query: newInputValue,
          inputId,
        });
      }
    },
    [inputId, loadOptions]
  );

  const { start: fetchOptionsDebounced, cancel: cancelFetchOptionsDebounced } = useDebounce(
    fetchOptions,
    LOAD_OPTIONS_DEBOUNCE_TIME
  );

  const updateInputValue = useCallback(
    (newInputValue: string): void => {
      fetchOptionsDebounced(newInputValue);
      setInputValue(newInputValue);
    },
    [fetchOptionsDebounced, setInputValue]
  );

  const handleInputChange = useCallback(
    (newInputValue: string): void => {
      updateInputValue(newInputValue);
      onInputChange?.(newInputValue);
    },
    [updateInputValue, onInputChange]
  );

  const handleInputFocus = useCallback((): void => {
    setInputFocused(true);
  }, [setInputFocused]);

  const handleInputBlur = useCallback(
    (e): void => {
      // Prevents focus out when the user is clicking on a suggestion
      if (!e.relatedTarget?.id?.includes(getSuggestionId(inputId))) {
        onBlur?.();
        setInputFocused(false);
      }
    },
    [inputId, onBlur, setInputFocused]
  );

  const handleKeydown = useCallback(
    (e: React.KeyboardEvent<Element>): void => {
      switch (e.key) {
        case KEYS.UP:
          e.preventDefault();
          setActiveIndex(
            typeof activeIndex === 'number' && activeIndex > 0
              ? activeIndex - 1
              : options.length - 1
          );
          break;
        case KEYS.DOWN:
          e.preventDefault();
          setActiveIndex(
            typeof activeIndex === 'number' && activeIndex < options.length - 1
              ? activeIndex + 1
              : 0
          );
          break;
        case KEYS.ENTER: {
          e.preventDefault();
          if (!inputValue) {
            return;
          }
          const selectedOption = typeof activeIndex === 'number' ? options[activeIndex] : undefined;
          // Once a user hits enter, stop the most recent debounce
          cancelFetchOptionsDebounced();
          handleSelectChange(
            selectedOption ?? {
              value: inputValue,
              label: inputValue,
            }
          );
          break;
        }
        default:
          break;
      }
    },
    [
      options,
      activeIndex,
      inputValue,
      setActiveIndex,
      cancelFetchOptionsDebounced,
      handleSelectChange,
    ]
  );

  // Persist the input value when the input is re-renderd
  useLayoutEffect(() => {
    if (inputRef?.current) {
      const currentDOMValue = inputRef.current.value;
      if (currentDOMValue !== inputValue) {
        updateInputValue(currentDOMValue);
      }
    }
  }, [inputValue, inputRef, updateInputValue]);

  return {
    handleInputChange,
    handleInputFocus,
    handleInputBlur,
    handleKeydown,
  };
};

export default useInputBodyActions;
