import React, { FC, useCallback } from 'react';
import styled from 'styled-components';

import { ClearSmall } from '@rover/icons';
import { Flex } from '@rover/kibble/core';
import { DSTokenMap } from '@rover/kibble/styles';
import KEYS from '@rover/react-lib/src/constants/keys.constants';
import { useI18n } from '@rover/rsdk/src/modules/I18n';

import LoadingIndicator from './LoadingIndicator';

// fine to use a custom button here its very unique to this component
// eslint-disable-next-line rover/prefer-kibble-components
const StyledClearButton = styled.button`
  border: none;
  padding: 0;
  display: flex;
  background-color: transparent;
  cursor: pointer;
  touch-action: none;
`;

type ClearButtonProps = {
  clearInputValue: () => void;
  loading?: boolean;
  inputRef?: HTMLInputElement | null;
  blurInput: () => void;
  onClear?: () => void;
};

const ClearButton: FC<ClearButtonProps> = ({
  clearInputValue,
  loading = false,
  inputRef,
  blurInput,
  onClear,
}) => {
  const { i18n } = useI18n();

  const onClearTextButtonClick = useCallback(
    (event: React.MouseEvent | React.TouchEvent | React.KeyboardEvent): void => {
      event.preventDefault();
      event.stopPropagation();
      onClear?.();
      clearInputValue();
    },
    [clearInputValue, onClear]
  );

  const handleClearButtonKeyDown = useCallback(
    (e: React.KeyboardEvent): void => {
      e.stopPropagation();
      if (e.key === KEYS.ENTER || e.key === KEYS.SPACE) {
        clearInputValue();
      }
    },
    [clearInputValue]
  );

  const handleClearButtonBlur = useCallback(
    (e: React.FocusEvent<HTMLButtonElement>): void => {
      // if we aren't tabbing to the input, clear the focus state
      if (!(e.relatedTarget === inputRef)) {
        blurInput();
      }
    },
    [blurInput, inputRef]
  );

  return (
    <Flex
      position="absolute"
      right={DSTokenMap.SPACE_2X}
      top="50%"
      sx={{ transform: 'translateY(-50%)' }}
    >
      {loading ? (
        <LoadingIndicator dataTestId="loading-indicator" />
      ) : (
        <StyledClearButton
          data-testid="clear-text-button"
          aria-label={i18n._('clear input text')}
          onClick={onClearTextButtonClick}
          onMouseDown={onClearTextButtonClick}
          onTouchStart={onClearTextButtonClick}
          onKeyDown={handleClearButtonKeyDown}
          onBlur={handleClearButtonBlur}
        >
          <ClearSmall height={24} width={24} />
        </StyledClearButton>
      )}
    </Flex>
  );
};

export default ClearButton;
