/* eslint-disable react/no-multi-comp */
import React, { PureComponent } from 'react';
import { MessageDescriptor } from '@lingui/core';
import isEqual from 'lodash-es/isEqual';
import omit from 'lodash-es/omit';

import type { Props as LocationInputProps } from '@rover/react-lib/src/components/formFields/LocationInput/LocationInput';
import LocationInput from '@rover/react-lib/src/components/formFields/LocationInput/LocationInput';
import { I18nType, useI18n } from '@rover/rsdk/src/modules/I18n';

import './LocationInput.types';

import { CURRENT_LOCATION_OPTION } from './LocationInput.constants';
import type { SelectOption } from './LocationInput.types';

type LatLng = {
  lat: string;
  lng: string;
};

export type Props = {
  i18n: I18nType;
  enableLatLng?: boolean;
  inputHeight?: string;
  fetchGeocodedLatLng: (arg0: {
    currentLocationValue: string;
    guaranteePostalCode: boolean;
    handleGeocodeError: () => void;
    inputId: string;
    region?: string;
    selectedOption?: SelectOption;
  }) => void;
  geocodedLatLng?: LatLng;
  geocodeResultsAreLoading?: boolean;
  guaranteePostalCode?: boolean;
  onClear?: () => void;
  onChange: (arg0: {
    label: string | null | undefined;
    lat?: string;
    lng?: string;
    locationAccuracy?: string;
    locationType?: string;
    rawLocationTypes?: string[];
    value: string | null | undefined;
  }) => void;
  onGeocodeError?: () => void;
  region?: string;
  selectedOption?: SelectOption;
} & LocationInputProps; // this component takes care of the lat/lng logic

class LocationInputWrapper extends PureComponent<Props> {
  componentDidUpdate(prevProps: Props) {
    if (!this.props.enableLatLng) {
      return;
    }

    const latLng = this.props.geocodedLatLng;
    const prevLatLng = prevProps.geocodedLatLng;

    if (latLng && !isEqual(prevLatLng, latLng)) {
      const { selectedOption } = this.props;
      this.props.onChange({ ...selectedOption, ...latLng });
    }
  }

  handleChange = (newSelectedOption: any, currentLocationValue: string) => {
    const {
      enableLatLng,
      fetchGeocodedLatLng,
      guaranteePostalCode = false,
      inputId,
      onChange,
      onGeocodeError,
      region,
    } = this.props;

    if (enableLatLng) {
      fetchGeocodedLatLng({
        currentLocationValue,
        guaranteePostalCode,
        handleGeocodeError: () => {
          onGeocodeError?.();
        },
        inputId,
        region,
        selectedOption: newSelectedOption,
      });
    } else {
      onChange(newSelectedOption);
    }
  };

  render() {
    const {
      i18n,
      disabled,
      enableLatLng, // eslint-disable-line @typescript-eslint/no-unused-vars
      fetchGeocodedLatLng, // eslint-disable-line @typescript-eslint/no-unused-vars
      geocodedLatLng, // eslint-disable-line @typescript-eslint/no-unused-vars
      geocodeResultsAreLoading,
      guaranteePostalCode = false, // eslint-disable-line @typescript-eslint/no-unused-vars
      loading,
      onChange, // eslint-disable-line @typescript-eslint/no-unused-vars
      onClear,
      onGeocodeError, // eslint-disable-line @typescript-eslint/no-unused-vars
      inputHeight,
      inputFontSize,
      ...other
    } = this.props;
    return (
      <LocationInput
        {...omit(other, ['selectedOption'])}
        inputHeight={inputHeight}
        inputFontSize={inputFontSize}
        disabled={disabled || geocodeResultsAreLoading}
        loading={loading || geocodeResultsAreLoading}
        onClear={onClear}
        onChange={(selectedOption) => {
          const modifiedSelectedOption = selectedOption;
          modifiedSelectedOption &&
            Object.keys(modifiedSelectedOption).forEach((key: string) => {
              modifiedSelectedOption[key] = i18n._(modifiedSelectedOption[key]);
            });
          this.handleChange(
            modifiedSelectedOption,
            i18n._(CURRENT_LOCATION_OPTION.value as MessageDescriptor)
          );
        }}
      />
    );
  }
} // this component takes care of the lat/lng logic

const LocationInputWrapperWithI18n = (props: Omit<Props, 'i18n'>): JSX.Element => {
  const { i18n } = useI18n();
  return <LocationInputWrapper {...props} i18n={i18n} />;
};

export default LocationInputWrapperWithI18n;
