import { useCallback, useEffect, useRef } from 'react';

const useDebounce = (
  callback: (...args: any[]) => void,
  delay: number
): { start: (...args: any[]) => void; cancel: () => void } => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const cancel = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, [timeoutRef]);

  const start = useCallback(
    (...args: any[]) => {
      if (timeoutRef.current) {
        cancel();
      }

      timeoutRef.current = setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [callback, cancel, timeoutRef, delay]
  );

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [timeoutRef]);

  return { start, cancel };
};

export default useDebounce;
