import { useEffect, useState } from 'react';

import usePrevious from '@rover/react-lib/src/hooks/usePrevious';

import type { SelectOption } from '../LocationInput.types';

type UseInputActiveIndex = {
  activeIndex: number | null;
  setActiveIndex: (index: number | null) => void;
};

const useInputActiveIndex = (
  options: SelectOption[],
  inputFocused: boolean
): UseInputActiveIndex => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const prevOptions = usePrevious(options);

  useEffect(() => {
    if (JSON.stringify(options) !== JSON.stringify(prevOptions)) {
      setActiveIndex(null);
    }
  }, [options, prevOptions]);

  useEffect(() => {
    if (!inputFocused) {
      setActiveIndex(null);
    }
  }, [inputFocused]);

  return { activeIndex, setActiveIndex };
};

export default useInputActiveIndex;
