import React, { FC, useEffect } from 'react';
import styled from 'styled-components';

import { DSTokenMap, Elevation } from '@rover/kibble/styles';
import Input from '@rover/react-lib/src/components/formFields/Input';
import { useI18n } from '@rover/rsdk/src/modules/I18n';

import useInputBodyActions from '../hooks/useInputBodyActions';
import type { SelectOption } from '../LocationInput.types';
import { getSuggestionId } from '../utils';

const StyledInput = styled(Input)<{
  focused: boolean;
  isLoading?: boolean;
  hasIcon: boolean;
  floatingMenu: boolean;
  suggestionsShown: boolean;
  inputHeight?: string;
  inputFontSize?: string;
  renderIconAlignment?: 'left' | 'right';
}>`
  input {
    min-height: ${({ inputHeight }) => {
      return inputHeight ?? '';
    }};

    text-overflow: ellipsis;
    padding-right: ${({ focused, isLoading, hasIcon, renderIconAlignment }) => {
      if (hasIcon) {
        return renderIconAlignment === 'right' ? DSTokenMap.SPACE_12X : '';
      }
      return focused || isLoading ? '40px' : '';
    }};
    padding-left: ${({ hasIcon, renderIconAlignment }) =>
      hasIcon && renderIconAlignment === 'left' ? DSTokenMap.SPACE_12X : ''};
    border-radius: ${({ floatingMenu, suggestionsShown }) => {
      if (!floatingMenu) {
        if (suggestionsShown) {
          return `${DSTokenMap.BORDER_RADIUS_PRIMARY} ${DSTokenMap.BORDER_RADIUS_PRIMARY} 0 0`;
        }

        return DSTokenMap.BORDER_RADIUS_PRIMARY;
      }

      return DSTokenMap.BORDER_RADIUS_SECONDARY;
    }};
    ${({ floatingMenu }) => (floatingMenu ? '' : Elevation(2))}

    &:focus {
      border: 1px solid ${DSTokenMap.BORDER_COLOR_INPUT_FOCUS.toString()};
      border-bottom-width: ${({ floatingMenu, suggestionsShown }) =>
        !floatingMenu && suggestionsShown ? 0 : ''};
    }

    &::placeholder {
      overflow: visible;
      text-overflow: ellipsis;
    }
  }
`;

type InputBodyProps = {
  inputId: string;
  inputRef?: React.MutableRefObject<HTMLInputElement | null>;
  inputFocused: boolean;
  inputAriaLabel?: string;
  inputFontSize?: string;
  inputHeight?: string;
  placeholder?: string;
  loading?: boolean;
  hasIcon: boolean;
  floatingMenu: boolean;
  showSuggestions: boolean;
  disabled?: boolean;
  validationType: 'popover' | 'inline';
  options?: SelectOption[];
  activeIndex?: number;
  inputValue: string;
  setActiveIndex: (index: number) => void;
  handleSelectChange: (option: SelectOption) => void;
  onBlur?: () => void;
  onInputChange?: (value: string) => void;
  loadOptions?: (params: { query: string; inputId: string }) => void;
  setInputValue: (value: string) => void;
  setInputFocused: (focused: boolean) => void;
};

const InputBody: FC<InputBodyProps> = (props) => {
  const { i18n } = useI18n();
  const {
    inputId,
    inputRef,
    inputFocused,
    inputAriaLabel = 'search for location',
    inputHeight,
    placeholder,
    inputFontSize,
    loading,
    hasIcon,
    floatingMenu,
    showSuggestions,
    disabled,
    validationType,
    options = [],
    activeIndex,
    inputValue,
    setActiveIndex,
    handleSelectChange,
    onBlur,
    onInputChange,
    loadOptions,
    setInputValue,
    setInputFocused,
  } = props;
  const { handleInputChange, handleInputFocus, handleInputBlur, handleKeydown } =
    useInputBodyActions(
      inputId,
      options,
      inputValue,
      setActiveIndex,
      handleSelectChange,
      setInputValue,
      setInputFocused,
      inputRef,
      activeIndex,
      onBlur,
      onInputChange,
      loadOptions
    );

  useEffect(() => {
    // eslint-disable-next-line rover/no-platform-specific-globals-or-imports -- using platform refinement
    if (process.env.JS_ENV_CLIENT && document.activeElement === inputRef?.current) {
      handleInputFocus();
    }
  }, []);

  return (
    <StyledInput
      id={inputId}
      inputHeight={inputHeight}
      inputFontSize={inputFontSize}
      focused={inputFocused}
      isLoading={loading}
      hasIcon={hasIcon}
      placeholder={placeholder}
      floatingMenu={!!floatingMenu}
      suggestionsShown={showSuggestions}
      data-testid="location-input"
      data-qa-id="location-input"
      autoComplete="off"
      inputRef={inputRef}
      value={inputValue}
      onChange={handleInputChange}
      disabled={disabled}
      onFocus={handleInputFocus}
      onBlur={handleInputBlur}
      onKeyDown={handleKeydown}
      validationType={validationType}
      aria-label={i18n._(inputAriaLabel)}
      aria-activedescendant={activeIndex ? getSuggestionId(inputId, activeIndex) : undefined}
    />
  );
};

export default InputBody;
