import React, { FC, useCallback } from 'react';
import { Trans } from '@lingui/react';
import styled from 'styled-components';

import { Text } from '@rover/kibble/core';
import { DSTokenMap, Elevation, ZIndex } from '@rover/kibble/styles';
import { captureError } from '@rover/rsdk/src/modules/ErrorReporting';
import { useI18n } from '@rover/rsdk/src/modules/I18n';

import { CURRENT_LOCATION_OPTION, SUGGESTION_QA_ID_PREFIX } from '../LocationInput.constants';
import { SelectOption } from '../LocationInput.types';
import { getSuggestionId, shouldShowCurrentLocation } from '../utils';

import HideBoxShadow from './HideBoxShadow';
import OptionLabel from './OptionLabel';

const Suggestions = styled.ul<{
  focused: boolean;
  floatingMenu: boolean;
  menuAlignment: 'left' | 'right';
}>`
  position: absolute;
  bottom: ${({ floatingMenu }) => (floatingMenu ? '-2px' : 0)};
  ${({ menuAlignment }) => menuAlignment}: 0;
  border-radius: ${({ floatingMenu }) =>
    floatingMenu
      ? DSTokenMap.BORDER_RADIUS_SECONDARY
      : `0 0 ${DSTokenMap.BORDER_RADIUS_SECONDARY} ${DSTokenMap.BORDER_RADIUS_SECONDARY}`};
  list-style-type: none;
  margin: 0;
  transform: translateY(100%);
  background-color: ${DSTokenMap.BACKGROUND_COLOR_PRIMARY.toString()};
  z-index: ${ZIndex.DROPDOWN.toNumber()};
  width: 100%;
  padding: 0;
  border: ${({ floatingMenu, focused }) =>
    `1px solid ${
      !floatingMenu && focused
        ? DSTokenMap.BORDER_COLOR_INPUT_FOCUS
        : DSTokenMap.BORDER_COLOR_PRIMARY
    }`};
  border-top-width: ${({ floatingMenu }) => (floatingMenu ? '' : 0)};
  overflow: hidden;
  min-width: ${({ floatingMenu }) => (floatingMenu ? '288px' : '')};
  ${Elevation(2)}
`;

const Suggestion = styled.li<{ isActive: boolean }>`
  padding: 0;
  display: flex;
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding: ${DSTokenMap.SPACE_2X} ${DSTokenMap.SPACE_3X};
  transition: background-color 0.15s ease-out;
  text-overflow: ellipsis;
  color: ${DSTokenMap.TEXT_COLOR_PRIMARY.toString()};
  background-color: ${({ isActive }) =>
    isActive
      ? DSTokenMap.BACKGROUND_COLOR_TERTIARY.toString()
      : DSTokenMap.BACKGROUND_COLOR_PRIMARY.toString()};
`;

type SuggestionsProps = {
  inputId: string;
  inputValue: string;
  suggestionsMenuAlignment?: 'left' | 'right';
  floatingMenu?: boolean;
  inputFocused: boolean;
  activeIndex: number | null;
  options: SelectOption[];
  handleSelectChange: (option: SelectOption) => void;
  setActiveIndex: (index: number) => void;
};

const LocationSuggestions: FC<SuggestionsProps> = (props) => {
  const { i18n } = useI18n();
  const {
    inputId,
    inputValue,
    suggestionsMenuAlignment = 'left',
    floatingMenu,
    inputFocused,
    activeIndex,
    options,
    handleSelectChange,
    setActiveIndex,
  } = props;

  const onSuggestionsButtonClick = useCallback(
    (event: React.MouseEvent, option: SelectOption): void => {
      event.preventDefault();
      handleSelectChange(option);
    },
    [handleSelectChange]
  );

  const formatOptionLabel = useCallback(
    (label: string): JSX.Element => {
      if (shouldShowCurrentLocation() && label === CURRENT_LOCATION_OPTION.label) {
        return (
          <OptionLabel title={i18n._(label)}>
            <Text size="100" fontWeight="semibold">
              <Trans id={label} />
            </Text>
          </OptionLabel>
        );
      }

      if (!inputValue) {
        return <OptionLabel title={label}>{label}</OptionLabel>;
      }

      try {
        const escapedInput = inputValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
        const parts: any[] = label.split(new RegExp(`(${escapedInput})`, 'gi'));

        return (
          <OptionLabel title={label}>
            {parts.map((part, index) => (
              <Text
                size="100"
                key={`${inputValue}-${index}`}
                {...(index % 2 ? { fontWeight: 'semibold' } : {})}
              >
                {part}
              </Text>
            ))}
          </OptionLabel>
        );
      } catch (e: any) {
        captureError(new Error(`${e.toString()}\n${JSON.stringify(inputValue)}`));
        return <OptionLabel title={label}>{label}</OptionLabel>;
      }
    },
    [inputValue, i18n]
  );

  const renderSuggestionsContent = useCallback((): JSX.Element[] => {
    return options.map((option, index) => {
      const isActive = activeIndex === index;
      return (
        <Suggestion
          key={option.label as string}
          role="option"
          id={getSuggestionId(inputId, index)}
          aria-selected={isActive}
          tabIndex={-1}
          data-qa-id={`${SUGGESTION_QA_ID_PREFIX}-${index}`}
          data-testid="suggestion"
          isActive={isActive}
          onMouseOver={() => setActiveIndex(index)}
          onFocus={() => {
            setActiveIndex(index);
          }}
          onClick={(event) => {
            onSuggestionsButtonClick(event, option);
          }}
        >
          {formatOptionLabel(option.label as string)}
        </Suggestion>
      );
    });
  }, [inputId, activeIndex, options, setActiveIndex, formatOptionLabel, onSuggestionsButtonClick]);

  return (
    <>
      <Suggestions
        role="listbox"
        data-qa-id="suggestions"
        data-testid="suggestions"
        floatingMenu={!!floatingMenu}
        focused={inputFocused}
        menuAlignment={suggestionsMenuAlignment}
        aria-hidden="true"
      >
        {renderSuggestionsContent()}
      </Suggestions>

      {/* render element to hide box shadow that appears between the
          input and suggestions to make it appear like one box shadow */}
      {!floatingMenu && <HideBoxShadow />}
    </>
  );
};

export default LocationSuggestions;
