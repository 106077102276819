import React, { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import { MessageDescriptor } from '@lingui/core';

import { Box } from '@rover/kibble/core';
import { DSTokenMap } from '@rover/kibble/styles';

import './LocationInput.types';

import ClearButton from './components/ClearButton';
import InputBody from './components/InputBody';
import LocationErrorModal from './components/LocationErrorModal';
import LocationSuggestions from './components/LocationSuggestions';
import useInputActiveIndex from './hooks/useInputActiveIndex';
import useInputValue from './hooks/useInputValue';
import type { SelectOption } from './LocationInput.types';
import { shouldShowCurrentLocation } from './utils';

export type Props = {
  className?: string;
  inputHeight?: string;
  inputFontSize?: string;
  clearOnFocus?: boolean;
  disabled?: boolean;
  inputAriaLabel?: string;
  dismissErrorMessage: (arg0: { inputId: string }) => void;
  errorMessage?: MessageDescriptor | string;
  floatingMenu?: boolean;
  inputId: string;
  inputValue?: string;
  loading?: boolean;
  loadOptions?: (arg0: { query: string; inputId: string }) => void;
  onBlur?: () => void;
  onClear?: () => void;
  onChange: (arg0: {
    label?: MessageDescriptor | string;
    lat?: string;
    lng?: string;
    locationAccuracy?: string;
    locationType?: string;
    rawLocationTypes?: string[];
    value?: MessageDescriptor | string;
    placeId?: string;
  }) => void;
  onFocus?: () => void;
  onInputChange?: (arg0: string) => void;
  options: SelectOption[];
  renderIcon?: (arg0: { focused: boolean }) => ReactNode;
  renderIconAlignment?: 'left' | 'right';
  renderIconAsPlaceholder?: boolean;
  suggestionsMenuAlignment?: 'left' | 'right';
  placeholder?: string;
  channel?: string;
  validationType: 'popover' | 'inline';
};

const LocationInput: FC<Props> = (props) => {
  const {
    className,
    inputValue: propInputValue,
    disabled,
    dismissErrorMessage,
    errorMessage,
    floatingMenu = true,
    inputId,
    loading,
    loadOptions,
    options,
    renderIcon,
    onClear,
    onBlur,
    onChange,
    onInputChange,
    renderIconAlignment = 'left',
    renderIconAsPlaceholder = false,
    suggestionsMenuAlignment,
    inputHeight,
    inputFontSize,
    inputAriaLabel,
    validationType,
    placeholder,
    clearOnFocus,
  } = props;

  const [inputFocused, setInputFocused] = useState(false);
  const { activeIndex, setActiveIndex } = useInputActiveIndex(options, inputFocused);
  const { inputValue, setInputValue } = useInputValue(
    propInputValue,
    errorMessage,
    clearOnFocus,
    inputFocused
  );

  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const showSuggestions = useMemo((): boolean => {
    if (shouldShowCurrentLocation()) {
      return Boolean(inputFocused && options.length);
    }

    return Boolean(inputFocused && inputValue && options.length);
  }, [inputFocused, options, inputValue]);

  const handleSelectChange = useCallback(
    (selectedOption: SelectOption): void => {
      onChange?.(selectedOption);
      setInputValue(selectedOption.label as string);
      setInputFocused(false);
      inputRef?.current?.blur();
    },
    [onChange, setInputValue]
  );

  return (
    <>
      <LocationErrorModal
        data-qa-id="error-modal"
        onRequestClose={() => {
          dismissErrorMessage({
            inputId,
          });
        }}
        message={errorMessage || ''}
      />
      <Box
        position="relative"
        width="100%"
        className={className}
        sx={{
          fontSize: inputFontSize ?? 'inherit',
        }}
      >
        <InputBody
          inputId={inputId}
          inputValue={inputValue}
          inputRef={inputRef}
          inputFocused={inputFocused}
          inputAriaLabel={inputAriaLabel}
          inputFontSize={inputFontSize}
          inputHeight={inputHeight}
          placeholder={placeholder}
          loading={loading}
          hasIcon={Boolean(renderIcon)}
          floatingMenu={!!floatingMenu}
          showSuggestions={showSuggestions}
          disabled={disabled}
          validationType={validationType}
          options={options}
          activeIndex={activeIndex !== null ? activeIndex : undefined}
          setActiveIndex={setActiveIndex}
          handleSelectChange={handleSelectChange}
          onBlur={onBlur}
          onInputChange={onInputChange}
          loadOptions={loadOptions}
          setInputValue={setInputValue}
          setInputFocused={setInputFocused}
        />

        {renderIcon &&
          !((inputValue && renderIconAsPlaceholder) || (renderIconAsPlaceholder && loading)) && (
            <Box
              position="absolute"
              {...(renderIconAlignment === 'left'
                ? { left: DSTokenMap.SPACE_4X }
                : { right: DSTokenMap.SPACE_4X })}
              top="50%"
              sx={{ transform: 'translateY(-50%)' }}
              width={DSTokenMap.SPACE_6X}
            >
              {renderIcon({
                focused: inputFocused,
              })}
            </Box>
          )}

        {showSuggestions && (
          <LocationSuggestions
            inputId={inputId}
            inputValue={inputValue}
            suggestionsMenuAlignment={suggestionsMenuAlignment}
            floatingMenu={floatingMenu}
            inputFocused={inputFocused}
            activeIndex={activeIndex}
            options={options}
            handleSelectChange={handleSelectChange}
            setActiveIndex={setActiveIndex}
          />
        )}

        {!!inputValue && inputFocused && (
          <ClearButton
            loading={loading}
            clearInputValue={() => {
              setInputValue('');
              inputRef.current?.focus();
            }}
            onClear={onClear}
            inputRef={inputRef.current}
            blurInput={() => {
              setInputFocused(false);
            }}
          />
        )}
      </Box>
    </>
  );
};

export default LocationInput;
